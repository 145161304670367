// Cognito Authentication Class
class CognitoAuth {
    constructor() {
        if (!window.AmazonCognitoIdentity) {
            throw new Error('AWS Cognito Identity SDK not loaded');
        }

        this.userPool = new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: window.awsConfig.userPoolId,
            ClientId: window.awsConfig.userPoolWebClientId
        });

        // Bind methods to maintain proper 'this' context
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.getUserAttributes = this.getUserAttributes.bind(this);
        this.getSession = this.getSession.bind(this);
    }

    async login(username, password) {
        if (!username || !password) {
            throw new Error('Username and password are required');
        }

        return new Promise((resolve, reject) => {
            const authData = {
                Username: username,
                Password: password
            };

            const authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData);
            const userData = {
                Username: username,
                Pool: this.userPool
            };

            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (session) => {
                    resolve({
                        user: cognitoUser,
                        session: session
                    });
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    resolve({
                        user: cognitoUser,
                        challengeName: 'NEW_PASSWORD_REQUIRED',
                        userAttributes,
                        requiredAttributes
                    });
                }
            });
        });
    }

    async logout() {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
            // Clear any stored tokens or session data
            localStorage.removeItem('authTokens');
            sessionStorage.removeItem('authTokens');
        }
    }

    async isAuthenticated() {
        try {
            const session = await this.getSession();
            return session !== null && session.isValid();
        } catch (error) {
            console.error('Authentication check failed:', error);
            return false;
        }
    }

    async getCurrentUser() {
        return this.userPool.getCurrentUser();
    }

    async getSession() {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.userPool.getCurrentUser();
            if (!cognitoUser) {
                resolve(null);
                return;
            }

            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(session);
            });
        });
    }

    async getUserAttributes() {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.userPool.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error('No authenticated user'));
                return;
            }

            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }

                if (!session.isValid()) {
                    reject(new Error('Session is invalid'));
                    return;
                }

                cognitoUser.getUserAttributes((err, attributes) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    resolve(attributes);
                });
            });
        });
    }
}

// Export for use in bundle
window.CognitoAuth = CognitoAuth;